html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background: #111;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  background: #111;
}
.chakra-popover__content:focus {
  box-shadow: none !important;
}
*::-webkit-scrollbar {
  width: 0.3em;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
 
*::-webkit-scrollbar-thumb {
  background-color: #a3e5fb88;
  border-radius: 4px;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px #efefef inset;
  color: white !important;
}